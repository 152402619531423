<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="radio-wrapper">
        <span
          class="option-td"
          v-for="(item, index) in options"
          :key="index"
          @click="linkTo(nameExtra.url + '/' + item.value)"
          :style="
            choosed === item.value
              ? { background: '#00508e', color: '#fff' }
              : {}
          "
          >{{ item.text }}</span
        >
      </div>
      <div class="wrapper-box">
        <div class="ele-item" v-for="(ele, index) in contentItems" :key="index">
          <div class="ele-inner-wrapper" @click="linkTo('/service/' + ele.id)">
            <div class="ele-a">
              <img :src="urlTrans(ele.url[0])" alt="" />
            </div>
            <div class="ele-b">
              <div class="b-des">
                <div class="b-des-title">
                  {{ ele.title }}
                </div>
                <div class="b-des-content">
                  <p>{{ ele.description }}</p>
                </div>
              </div>
            </div>
            <div class="ele-c">
              <div class="b-link">
                <p>查看详情 ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper">
        <Page
          :total="totalItems"
          :page-size="Tpage.currentSize"
          @on-change="handlePage"
          class-name="page-d"
        />
      </div>
      <Spin v-if="spin" fix size="large"></Spin>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
import breadcrumbsText from "@/libs/mixins/BreadcrumbsText.js";
export default {
  name: "serviceList",
  components: {
    breadcrumbs,
  },
  props: {
    choosed: {
      type: String,
      required: true,
      default: "all",
    },
  },
  mixins: [breadcrumbsText],
  data() {
    return {
      nameExtra: {
        url: "/services_list",
        text: "我们的服务",
      },
      options: [
        { text: "全部", value: "all" },
        // { text: "检测试验", value: "test" },
        // { text: "检验评估", value: "evaluate" },
        // { text: "认证服务", value: "certification" },
        // { text: "科技创新", value: "innovation" },
      ],
      contentItems: [
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/risk.jpg",
        //   theSrc: "/risk_nav",
        //   title: "检测试验",
        //   description:
        //     "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        // },
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/report.jpg",
        //   theSrc: "/conclusion/report_l",
        //   title: "检验评估",
        //   description:
        //     "城市轨道交通运营安全评价；车辆服役安全评估；覆盖轨道交通系统从规划、设计、制造、建设、安装、调试、试运行、运营及维护阶段全生命周期的符合性评估。",
        // },
        // {
        //   imgUrl: process.env.BASE_URL + "img/index/accident.jpg",
        //   theSrc: "/conclusion/accident_l",
        //   title: "认证服务",
        //   description:
        //     "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        // },
      ],
      spin: false,
      Tpage: {
        currentPage: 1,
        currentSize: 6,
      },
      totalItems: 0,
      code: "services",
      lang: "中文",
      keyword: "",
    };
  },
  created() {
    this.getData();
    this.getOption();
  },
  methods: {
    getOption() {
      this.$axios
        .post(this.$url + "/option", {
          askData: this.code,
          lang: this.lang,
        })
        .then((response) => {
          for (let i of response.data.res_record.category) {
            this.options.push(i);
          }
        });
    },
    handlePage(value) {
      this.Tpage.currentPage = value;
      this.getData();
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + "/flist", {
          page: this.Tpage,
          askData: this.code,
          keyword: this.keyword,
          choosed: this.choosed,
          lang: this.lang,
        })
        .then((response) => {
          this.contentItems = response.data.res_record;
          this.totalItems = response.data.total_n;
          this.spin = false;
        });
    },
    linkTo: function (value) {
      this.spin = true;
      location.href = value;
    },
    urlTrans(url) {
      // return url;
      if (url) {
        return this.$fileUrlFacWithoutRight(url);
      } else {
        return "";
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.ele-item {
  width: 420px;
  padding: 15px 15px;
}

.ele-inner-wrapper {
  border: 1px solid #eee;
  cursor: pointer;
  padding: 10px;
}

.ele-a {
  overflow: hidden;
}

@keyframes it {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.ele-a img:hover {
  animation: it 10s ease 0s forwards;
}

.ele-a {
  text-align: center;
}

.ele-a img {
  width: 370px;
  height: 210px;
  display: block;
}

.b-des {
  padding: 0 10px;
}

.b-des-title {
  font-size: ;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, Tahoma, "Microsoft YaHei",
    "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei",
    sans-serif;
  outline: none;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.333;
}

.ele-b {
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.b-des-content {
  color: rgb(165, 165, 165);
  font-size: 14px;
  line-height: 184%;
  font-family: "微软雅黑";
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 13px;
  white-space: normal;
}

.ele-c {
  padding: 0 10px;
}

.b-link {
  font-size: 14px;
  color: rgb(192, 0, 0);
  line-height: 184%;
}

.page-wrapper {
  text-align: center;
  padding: 50px 0;
}

.radio-wrapper {
  position: absolute;
  right: 0;
  top: 50px;
}

.option-td {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-right: none;
}

.option-td:last-child {
  border-right: 1px solid #ddd;
}

.option-td:hover {
  outline: 1px solid #00508e;
  outline-offset: -1px;
}
</style>